<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <div class="text-center">
            <v-dialog
                    v-model="dialog"
                    persistent
                    width="50"
            >
                <img src='../../assets/loader_spinner.gif' style="width: 50px; ">

            </v-dialog>
        </div>
        <div class="text-center">
            <v-dialog
                    v-model="dialog2"
                    persistent
                    width="400"
            >
                <v-sheet
                        :elevation="18"
                        class="mx-auto"
                        height="160"
                        width="400"
                >
                    <div style="font-weight: bold; font-size: 1.5rem; text-align: center; padding:20px;">
                        <span style="color: #B71C1C;">휴업기간입니다.</span> <br><span>[{{stopDate}}]</span>
                    </div>

                    <v-btn
                            color="primary"
                            class="mx-3 white--text center-block tmp-btn"
                            style="float: right; margin-right:40px;"
                            small
                            @click="dialog2 = false"
                    >
                        확인
                    </v-btn>
                </v-sheet>

            </v-dialog>
        </div>
        <div class="text-center">
            <v-dialog
                    v-model="dialog3"
                    persistent
                    width="300"
            >
                <v-date-picker
                        v-if="dialog3"
                        show-adjacent-months
                        v-model="startDate"
                        :min="today"
                        color="primary"
                        class="custom-picker"
                        :weekday-format="getDay"
                        :month-format="getMonth"
                        :title-date-format="getMonth"
                        :header-date-format="getHeaderTitleMonth"
                >
                    <v-spacer />
                    <v-btn
                            text
                            class="font-weight-bold"
                            color="gray"
                            @click="selectDate(0)"
                    >
                        취소
                    </v-btn>
                    <v-btn
                            text
                            class="font-weight-bold"
                            color="primary "
                            @click="selectDate(1)"
                    >
                        확인
                    </v-btn>
                </v-date-picker>

            </v-dialog>
        </div>
        <v-row>
            <v-col>

                <v-row class="sub-title-img-pc hidden-sm-and-down" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                            실시간 예약
                        </h1>
                    </v-col>
                </v-row>
                <v-row class="sub-title-img-mo hidden-md-and-up" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                            실시간 예약
                        </h1>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pa-0">
                <v-container class="my-8">
                    <v-row class="px-3">
                        <v-col cols="3" class="hidden-md-and-down"></v-col>
                        <v-col cols="12" md="6">
                            <table class="resvFormTable1">
                                <colgroup>
                                    <col class="" style="width:20%"/>
                                    <col style="width:80%"/>
                                </colgroup>
                                <tr>
                                    <th class="">입실일</th>
                                    <td style="display: flex">
                                        <input type="text" id="startDate" class="dateRange"
                                               name="startDate"
                                               @click="openDatePecker"
                                               readonly placeholder="입실일을 선택하세요."/>

                                    </td>
                                </tr>
                                <!--<tr>
                                    <th class="">입실일</th>
                                    <td style="display: flex">
                                        <input type="text" id="dateRange" class="dateRange" name="dateRange"
                                               readonly placeholder="입실일을 선택하세요."/>

                                    </td>
                                </tr>-->
                                <tr>
                                    <th class="">숙박일</th>
                                    <td style="display: block">
                                        <select v-model="dateRange">
                                            <!--<option v-for="(n, i) in 5" :value="n">{{n}}</option>-->
                                            <option value="0">숙박일선택</option>
                                            <option value="1">1박/2일</option>
                                            <option value="2">2박/3일</option>
                                            <option value="3">3박/4일</option>
                                            <option value="4">4박/5일</option>
                                            <option value="5">5박/6일</option>
                                        </select>

                                        <v-btn
                                                class="mx-2 white--text center-block btn-gray float-right"
                                                @click="btnClick"
                                        >
                                            검색
                                        </v-btn>
                                    </td>
                                </tr>
                            </table>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-row>
                                <v-col cols="12" class="hidden-sm-and-down">
                                    <ul class="resvDateRangeBox">
                                        <li class="">
                                            <div>
                                                <span class="resvDateRangeSub"></span>
                                                <template v-if="dates.startDate == '-'">
                                                    -
                                                </template>
                                                <template v-else>
                                                    {{dates.startDate}}({{dates.startDow}})
                                                </template>

                                            </div>
                                        </li>
                                        <li class="resvDateRangeBox_dateRage">
                                            {{dates.dateRange}}
                                        </li>
                                        <li class="">
                                            <div>
                                                <span class="resvDateRangeSub"></span>
                                                <template v-if="dates.startDate == '-'">
                                                    -
                                                </template>
                                                <template v-else>
                                                    {{dates.endDate}}({{dates.endDow}})
                                                </template>

                                            </div>
                                        </li>
                                    </ul>
                                </v-col>
                                <v-col cols="12" class="hidden-md-and-up">
                                    <table class="resvDateRangeTable">
                                        <tr>
                                            <td>
                                                <div v-if="dates.startDate == '-'">
                                                    -
                                                </div>
                                                <div v-else>
                                                    {{dates.startDate}}({{dates.startDow}})
                                                </div>
                                            </td>
                                            <td class="resvDateRangeTable_dateRage">
                                                {{dates.dateRange}}
                                            </td>
                                            <td>

                                                <div v-if="dates.startDate == '-'">
                                                    -
                                                </div>
                                                <div v-else>
                                                    {{dates.endDate}}({{dates.endDow}})
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-row>
                            <v-row v-if="isSearch">
                                <v-col cols="12" class="hidden-sm-and-down">
                                    <ul class="roundBtn-blue calc-5 searchGubun">
                                        <li><a @click="clickSearchGubun('R')" class="searchGubun_R">객실</a></li>
                                        <li><a @click="clickSearchGubun('A')" class="searchGubun_A">캠핑장A</a></li>
                                        <li><a @click="clickSearchGubun('B')" class="searchGubun_B">캠핑장B</a></li>
                                        <li><a @click="clickSearchGubun('C')" class="searchGubun_C">캠핑장C</a></li>
                                        <li><a @click="clickSearchGubun('D')" class="searchGubun_D">캠핑장D</a></li>
                                    </ul>
                                </v-col>
                                <v-col cols="12" class="hidden-md-and-up">
                                    <ul class="roundBtn-blue calc-3 searchGubun">
                                        <li><a @click="clickSearchGubun('R')" class="searchGubun_R">객실</a></li>
                                        <li><a @click="clickSearchGubun('A')" class="searchGubun_A">캠핑장A</a></li>
                                        <li><a @click="clickSearchGubun('B')" class="searchGubun_B">캠핑장B</a></li>
                                    </ul>
                                    <ul class="roundBtn-blue calc-3 searchGubun">
                                        <li><a @click="clickSearchGubun('C')" class="searchGubun_C">캠핑장C</a></li>
                                        <li><a @click="clickSearchGubun('D')" class="searchGubun_D">캠핑장D</a></li>
                                    </ul>
                                </v-col>
                            </v-row>

                            <v-row v-if="isSearch">
                                <v-col cols="12" class="reservation-select-title">
                                    예약가능 리스트
                                </v-col>
                                <table class="resvTable hidden-sm-and-down">
                                    <colgroup>
                                        <col style="width:10%"/>
                                        <col style="width:20%"/>
                                        <col style="width:25%"/>
                                        <col style="width:20%"/>
                                        <col style="width:10%"/>
                                        <col style="width:15%"/>
                                    </colgroup>
                                    <tr>
                                        <th></th>
                                        <th>객실명</th>
                                        <th>형태</th>
                                        <th>기준/최대</th>
                                        <th>예약인원</th>
                                        <th class="num-right">이용요금</th>
                                    </tr>
                                    <tr
                                            v-for="(item,i) in resvSelectList"
                                            :key="i">
                                        <td class="mx-auto">
                                            <template v-if="dateRange > 0">
                                                <v-checkbox
                                                        v-model="item.isCheck"
                                                        color="primary accent-3"
                                                        class="shrink ml-3">
                                                </v-checkbox>
                                            </template>
                                            <template v-else>
                                                <v-checkbox
                                                        v-model="item.isCheck"
                                                        color="primary accent-3"
                                                        class="shrink ml-3"
                                                        disabled>
                                                </v-checkbox>
                                            </template>

                                        </td>
                                        <td>{{item.roomNm}}</td>
                                        <td>{{item.roomForm}}</td>
                                        <td>{{item.stdNop}}명/{{item.maxNop}}명</td>
                                        <td>
                                            <template v-if="dateRange > 0">
                                                <select v-model="item.resvNop" @change="selectChange($event, item)">
                                                    <option v-for="(n, i) in item.maxNopNum" :value="n">{{n}}</option>
                                                </select>
                                            </template>
                                            <template v-else>
                                                <select disabled>
                                                    <option value="0">0</option>
                                                </select>
                                            </template>
                                        </td>
                                        <td class="num-right">
                                            <template v-if="item.isSale=='true'">
                                                <span style="text-decoration: line-through; color:red;">{{item.oldPrice | comma}}원</span>
                                                <br>
                                            </template>
                                            <span style="color:#1976d2; font-weight: bold;">{{item.totalPrice | comma}}원</span>
                                        </td>
                                    </tr>
                                </table>
                                <table class="resvTable hidden-md-and-up" style="border-top: 1px black solid;">
                                    <colgroup>
                                        <col style="width:10%"/>
                                        <col style="width:30%"/>
                                        <col style="width:25%"/>
                                        <col style="width:35%"/>
                                    </colgroup>

                                    <tr
                                            v-for="(item,i) in resvSelectList"
                                            :key="i">
                                        <td class="mx-auto">
                                            <template v-if="dateRange > 0">
                                                <v-checkbox
                                                        v-model="item.isCheck"
                                                        color="primary accent-3"
                                                        class="shrink ml-3">
                                                </v-checkbox>
                                            </template>
                                            <template v-else>
                                                <v-checkbox
                                                        v-model="item.isCheck"
                                                        color="primary accent-3"
                                                        class="shrink ml-3"
                                                        disabled>
                                                </v-checkbox>
                                            </template>

                                        </td>
                                        <td>{{item.roomNm}} <br> <span style="font-size: 0.7rem; color: gray;">{{item.roomForm}}</span>
                                        </td>
                                        <td>{{item.stdNop}}명/{{item.maxNop}}명 <br>
                                            <template v-if="dateRange > 0">
                                                <select v-model="item.resvNop" @change="selectChange($event, item)"
                                                        style="height: 25px !important; padding:0 5px; !important; margin-bottom: 5px; ">
                                                    <option v-for="(n, i) in item.maxNopNum" :value="n">{{n}}</option>
                                                </select>
                                            </template>
                                            <template v-else>
                                                <select disabled
                                                        style="height: 25px !important; padding:0 5px; !important;margin-bottom: 5px; ">
                                                    <option value="0">0</option>
                                                </select>
                                            </template>
                                        </td>
                                        <td class="num-right">
                                            <template v-if="item.isSale=='true'">
                                                <span style="text-decoration: line-through; color:red;">{{item.oldPrice | comma}}원</span>
                                                <br>
                                            </template>
                                            <span style="color:#1976d2; font-weight: bold;">{{item.totalPrice | comma}}원</span>
                                        </td>
                                    </tr>
                                </table>
                                <v-col cols="12">
                                    <span style="color:black; font-weight: bold; font-size: 1rem;">
                                        ※ 다른 상품 추가 필요시 '다음단계'에서 예약 추가를 선택하세요.
                                    </span>

                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col class="hidden-md-and-up" style="height: 100px; text-align: center;">
                                    <div style="width: 100%;">
                                    <span style="color:black; font-weight: bold; font-size: 1rem; text-align: center;">
                                        ※ 입실일과 숙박일을 선택하시면
                                    </span>
                                        <span style="color:black; font-weight: bold; font-size: 1rem; text-align: center; margin-left: 10px;">
                                        <br>예약가능 리스트가 표시됩니다.
                                    </span>
                                    </div>
                                </v-col>
                                <v-col class="hidden-sm-and-down" style="height: 100px; text-align: center;">
                                    <span style="color:black; font-weight: bold; font-size: 1rem; text-align: center;">
                                        ※ 입실일과 숙박일을 선택하시면 예약가능 리스트가 표시됩니다.
                                    </span>
                                </v-col>

                            </v-row>

                            <v-row v-if="isSearch">
                                <v-col cols="12" class="hidden-sm-and-down">
                                    <ul style="" class="roundBtn calc-4">
                                        <li></li>
                                        <li class="roundBtn-blue-li">
                                            <a @click="revSelectBtnClick('1')">선택취소</a>
                                        </li>
                                        <li class="roundBtn-red-li">
                                            <a @click="revSelectBtnClick('3')">다음단계</a>
                                        </li>
                                        <li></li>
                                    </ul>
                                </v-col>
                                <v-col cols="12" class="hidden-md-and-up">
                                    <ul style="width:100%; margin:0 auto !important;" class="roundBtn calc-2">
                                        <li class="roundBtn-blue-li">
                                            <a @click="revSelectBtnClick('1')">선택취소</a>
                                        </li>
                                        <li class="roundBtn-red-li">
                                            <a @click="revSelectBtnClick('3')">다음단계</a>
                                        </li>
                                    </ul>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    export default {
        name: 'ReservationSearch',
        components: {},
        data: () => ({
            dialog: false,
            dialog2: false,
            dialog3: false,
            stopDate: "",
            loading: true,
            searchGubun: "R",
            dateRange: 0,
            today: "",
            startDate : new Date().toISOString().substr(0, 10),
            startDate_search: "",
            dates: {startDate: "", startDow: "", endDate: "", endDow: "", dateRange: "1"},
            items: ['객실', '캠핑장A', '캠핑장B', '캠핑장C', '캠핑장D'],
            isConfirm: false,
            isSearch: false,
            resvSelectList: []


        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);

            this.$nextTick(function () {

                //let date = new Date();
                //date.setDate(date.getDate() + 1);

                let _this = this;
                this.today = new Date().toISOString().substr(0, 10);
                this.dates.startDate = this.today;

                let todayYear = this.$getTodayYear();

                $('#dateRange').daterangepicker({
                    "locale": {
                        "format": "YYYY-MM-DD",
                        "separator": " ~ ",
                        "applyLabel": "확인",
                        "cancelLabel": "취소",
                        "fromLabel": "From",
                        "toLabel": "To",
                        "customRangeLabel": "Custom",
                        "weekLabel": "W",
                        "daysOfWeek": ["일", "월", "화", "수", "목", "금", "토"],
                        "monthNames": ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
                    },
                    singleDatePicker: true,
                    showDropdowns: true,
                    minYear:todayYear,
                    maxYear:todayYear+3,
                    "drops": "auto",
                    "minDate": new Date()
                }, function (start, end, label) {
                });

                // 확인 이벤트
                $('#dateRange').on('apply.daterangepicker', function (ev, picker) {
                    _this.isConfirm = true;
                    //_this.btnClick();
                });

                // 취소이벤트
                $('#dateRange').on('cancel.daterangepicker', function (ev, picker) {
                    //_this.btnClickCancel()
                    _this.isConfirm = false;
                });

                // 오픈 이벤트
                $('#dateRange').on('show.daterangepicker', function (ev, picker) {
                    _this.isConfirm = false;
                });

                // 닫기 이벤트
                $('#dateRange').on('hide.daterangepicker', function (ev, picker) {
                    setTimeout(() => {
                        //_this.hidedaterangepicker();
                    }, 500);

                });

                this.init('R')


               /* if (this.$route.params.roomGubun != undefined) {
                    //this.searchGubun = this.$route.params.roomGubun;
                    this.init(this.$route.params.roomGubun)
                } else {

                }*/

                //this.btnClick();

            })

        },
        methods:
            {
                preventNav(event) {
                    event.preventDefault();
                    event.returnValue = ""
                },
                getDates() {

                    //let startdate = $('#dateRange').val();
                    let startdate = $('#startDate').val();
                    let dateRange = parseInt(this.dateRange);

                    if(startdate.indexOf('-') === -1){

                        alert("날짜를 선택해 주세요.")
                        return;
                    }

                    if(dateRange === 0 ){

                        alert("숙박일을 선택해 주세요.")
                        return;
                    }


                    let parts = startdate.split("-");
                    let dt = new Date(
                        parseInt(parts[0], 10),      // year
                        parseInt(parts[1], 10) - 1,  // month (starts with 0)
                        parseInt(parts[2], 10)       // date
                    );

                    dt.setDate(dt.getDate() + dateRange);
                    parts[0] = "" + dt.getFullYear();
                    parts[1] = "" + (dt.getMonth() + 1);
                    if (parts[1].length < 2) {
                        parts[1] = "0" + parts[1];
                    }
                    parts[2] = "" + dt.getDate();
                    if (parts[2].length < 2) {
                        parts[2] = "0" + parts[2];
                    }

                    let enddate = parts.join("-");

                    this.dates.startDate = startdate;
                    this.dates.endDate = enddate;
                    //this.dates.dateRange = this.dateRange;
                    this.dates.dateRange = dateRange + "박 / " + (dateRange + 1) + "일";
                    let week = ['일', '월', '화', '수', '목', '금', '토'];
                    this.dates.startDow = week[new Date(this.dates.startDate).getDay()];
                    this.dates.endDow = week[new Date(this.dates.endDate).getDay()];

                    return true;

                },

                btnClick() {
                    this.dialog = true;
                    let _this = this;
                    let isDateCheck = this.getDates();



                    if(isDateCheck){
                        let formdata = {
                            "resvStartDate": this.dates.startDate,
                            "resvEndDate": this.dates.endDate,
                            "resvDayRage": this.dateRange,
                            "roomGubun": this.searchGubun,
                            "useYn": 'Y',
                            "delYn": 'N',

                        };

                        return this.$store.dispatch("reservation/getRoomKate", formdata)
                            .then((resp) => {
                                setTimeout(() => {

                                    let isStop = false;
                                    let stopDate = "";

                                    resp.message[0].roomList.forEach(function (e) {
                                        if (e.isStop === 'true') {
                                            isStop = true;
                                            stopDate = e.stopDate;
                                        }
                                    })

                                    if (isStop) {
                                        //alert("선택하신 기간은 휴업기간 입니다. (" + stopDate + ")");
                                        this.stopDate = stopDate;
                                        this.dialog2 = true;
                                        _this.init('R');
                                    } else {
                                        this.resvSelectList = JSON.parse(JSON.stringify(resp.message[0].roomList));
                                        this.isSearch = true;
                                        this.$nextTick(function () {
                                            this.init_btn(this.searchGubun);
                                        })

                                    }

                                }, 1000);

                                setTimeout(() => {
                                    this.dialog = false;
                                }, 1300);
                            })
                            .catch((err) => {
                            })
                    } else {
                        //alert("잘못된 요청입니다.")
                        //this.init('R');
                        this.dialog = false;
                    }


                },
                // 달력 닫는 이벤트
                hidedaterangepicker() {

                    if (!this.isConfirm) {
                        alert("날짜를 2일이상 선택해 주세요.");
                        this.init('R');

                    }


                },
                selectChange(event, item) {

                    let stdNop = parseInt(item.stdNop);

                    if (event.target.value > stdNop) {
                        item.totalPrice = parseInt(item.price) + ((item.osrScg * (event.target.value - stdNop)) * parseInt(this.dateRange))


                    } else {
                        item.totalPrice = parseInt(item.price);
                    }

                },
                revSelectBtnClick(val) {

                    switch (val) {
                        case "1" : { //취소
                            for (let i = 0; i < this.resvSelectList.length; i++) {
                                this.resvSelectList[i].isCheck = false;

                            }

                            break;
                        }
                        case "2": { //장바구니
                            //this.$store.state.reservation.resvConfirmList = [];
                            let resvConfirmList = [];

                            for (let i = 0; i < this.resvSelectList.length; i++) {
                                if (this.resvSelectList[i].isCheck) {

                                    this.$store.commit("reservation/SAVE_BASKET", this.resvSelectList[i]);
                                    resvConfirmList.push(this.resvSelectList[i])
                                }
                            }

                            if (resvConfirmList.length < 1) {
                                alert("예약할 객실을 선택해 주세요.");
                                return;
                            }

                            this.$router.push({path: "/reservation/search"});
                            //location.href = '/reservation/search';
                            break;
                        }
                        case "3": { //다음단계
                            this.dialog = true;
                            //this.$store.state.reservation.resvConfirmList = [];
                            this.$store.commit("reservation/CLEAR_RESV_CONFIRM");

                            let resvConfirmList = [];

                            for (let i = 0; i < this.resvSelectList.length; i++) {

                                if (this.resvSelectList[i].isCheck) {

                                    if (this.resvSelectList[i].isStop === 'true') {
                                        alert("선택한 기간은 휴업기간입니다. 다른날을 선택해 주세요.");
                                        this.dialog = false;
                                        return;
                                    }

                                    resvConfirmList.push(this.resvSelectList[i])

                                }
                            }

                            if (resvConfirmList.length < 1) {
                                alert("예약할 객실을 선택해 주세요.");
                                this.dialog = false;
                                return;
                            }

                            setTimeout(() => {

                                let formdata = {
                                    //resvConfirmList: resvConfirmList,
                                    std: this.dates.startDate,
                                    edd: this.dates.endDate
                                };

                                this.$store.commit("reservation/SAVE_RESV_CONFIRM", resvConfirmList);
                                location.href = "/reservation/paymentTmp" + '?std=' + this.dates.startDate + '&edd=' + this.dates.endDate;
                                //this.dialog = false;
                            }, 1300);


                            break;
                        }

                    }
                },
                clickSearchGubun(val) {

                    //$('#searchGubun > li > a').removeClass("active");
                    //$("#searchGubun_" + val).addClass("active");

                    $('.searchGubun > li > a').removeClass("active");
                    $(".searchGubun_" + val).addClass("active");

                    this.searchGubun = val;

                    let dateRange = $('#startDate').val();

                    if (dateRange.length > 0) {
                        this.btnClick();
                    } else {
                        this.init(val);
                    }

                },
                // 구분버튼 초기화
                init_btn(val){

                    $('.searchGubun > li > a').removeClass("active");
                    $(".searchGubun_" + val).addClass("active");
                },
                // 초기화
                init(val) {
                    this.isSearch = false;
                    $('#dateRange').val("");


                    this.dates = {startDate: "-", startDow: "-", endDate: "-", endDow: "-", dateRange: "-"};
                    this.dateRange = 0;

                    //$('#searchGubun > li > a').removeClass("active");
                    //$("#searchGubun_" + val).addClass("active");

                    $('.searchGubun > li > a').removeClass("active");
                    $(".searchGubun_" + val).addClass("active");

                    this.searchGubun = val;

                    /*let fromdata = {
                        roomGubun: this.searchGubun,
                        useYn: "Y"

                    };*/

                    /*return this.$store.dispatch("room/getRoomList", fromdata)
                        .then((resp) => {
                            setTimeout(() => {

                                resp.message.forEach(function (e) {
                                    e["totalPrice"] = 0;
                                    e["resvNop"] = Number(e.stdNop);
                                    e["maxNopNum"] = Number(e.maxNop);
                                });

                                this.resvSelectList = (resp.message);

                            }, 300)
                        })
                        .catch((err) => {
                        })*/

                },
                getDay(date) {
                    const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
                    let i = new Date(date).getDay(date);
                    return daysOfWeek[i];
                },
                getMonth(date) {
                    const monthName = [
                        '1월',
                        '2월',
                        '3월',
                        '4월',
                        '5월',
                        '6월',
                        '7월',
                        '8월',
                        '9월',
                        '10월',
                        '11월',
                        '12월',
                    ];

                    let i = new Date(date).getMonth(date);
                    return monthName[i];
                },
                getHeaderTitleMonth(date) {
                    const monthName = [
                        "1월",
                        "2월",
                        "3월",
                        "4월",
                        "5월",
                        "6월",
                        "7월",
                        "8월",
                        "9월",
                        "10월",
                        "11월",
                        "12월",
                    ];
                    let i = new Date(date).getMonth(date);

                    const year = new Date(date).getFullYear(date);

                    const month = monthName[i];

                    return year +'년  ' + month;
                },
                openDatePecker(){
                    this.dialog3 = true;

                },
                selectDate(val){

                    if(val > 0){
                        this.startDate_search = this.startDate;
                        $('#startDate').val(this.startDate);
                        this.init('R');
                        //this.dates.startDate = this.startDate;
                    }else {

                        //this.startDate_search = this.startDate;
                        $('#startDate').val(this.startDate_search)
                    }


                    this.dialog3 = false;
                }
            }
        ,
        mounted() {
            this.$nextTick(() => {


            })
        },
        computed: {

        },
        updated: function () {  // 페이지 전부 생성후 자동 실행

        }
    }
</script>

<style>

    .v-input--selection-controls {
        margin-top: 3px !important;
    }

    .v-input__control {
        height: 30px !important;
    }

    .custom-picker {
        border-radius: 10px !important;
    }

    .v-btn--active.green {


    }
    .v-btn__content {

    }
    .v-picker__title {
        display: none !important;
    }


    .v-date-picker-header {
        padding-top: 10px !important;

    }


</style>
